import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Team from "../components/Common/Team";
import FunFactsArea from "../components/Common/FunFactsArea";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import FeedbackStyleFour from '../components/Common/FeedbackStyleFour';
import Partner from '../components/MachineLearning/Partner';
import { Link } from "gatsby";

import AboutImg from "../assets/images/about4.png";

const About2 = () => (
    <Layout>
        <SEO title="À propos" /> 

        <Navbar />

        <PageBanner pageTitle="À propos" />

        <div className="about-area ptb-80">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="ml-about-img">
                            <img src={AboutImg} alt="image" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="ml-about-content">
                            <span className="sub-title">À propos</span>
                            <h2>En Quelques Mots</h2>
                            <div className="bar"></div>

                            <p>Fondée par une équipe polynésienne, nous connaissons toutes les contraintes qu’imposent Tahiti & ses îles. Nous sommes le premier service indépendant d’hébergement Web &amp; Jeux à Tahiti, proposant des serveurs locaux.</p>

                            <p>Fenua Hosting, le plus rapide du Pacifique.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Notre Histoire</h3>
                                <p>FenuaHosting est le premier à vous offrir une game complète d'hébergement à Tahiti. Nous sommes fiers de pouvoir vous proposer de l'hébergement de sites internet, d'email et de serveurs de jeux à Tahiti, dans la région du Pacifique.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Notre Mission</h3>
                                <p>Vous offrire une solution unique, performante et peu onéreuse au fenua.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Qui sommes-nous ?</h3>
                                <p>Nous sommes une jeune équipe polynésienne passionée par l'informatique. Nous connaissons parfaitement les contraintes qu’imposent Tahiti & ses îles nous permettant de vous offrir des services ultra performants!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-cta-box">
                    <h3>Vous avez des questions ?</h3>
                    <p>N'hésitez pas à nous contacter</p>

                    <Link to="/contact" className="btn btn-primary">
                        Contactez-nous !
                    </Link>
                </div>
        </div>
        
        <Footer />
    </Layout>
)

export default About2;
